<template>
  <div class="pages">
    <header>
      <img src="@/assets/logo.png" alt="logo" />
      <!-- <h2>佛山潭洲陶瓷展</h2> -->
      <span>展位销售管理系统2024</span>
    </header>
    <div class="formbox">
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <h2>用户登录</h2>
        <a-form-item label="用户名" v-bind="validateInfos.username">
           <a-input v-model:value="ruleForm.username"></a-input>
        </a-form-item>
        <a-form-item label="密码" v-bind="validateInfos.password">
            <a-input type="password" v-model:value="ruleForm.password" autocomplete="off"></a-input>
        </a-form-item>
       
        <a-row>
          <a-col :span="4"></a-col>
          <a-col :span="18">
            <div class="jl pc" style="margin-bottom:15px;">
              <a-checkbox v-model:checked="checked">记住密码</a-checkbox>
            </div>
            <a-button class="pc" block type="primary" @click="submitForm('ruleForm')" :loading="loading">登 录</a-button>
          </a-col>
        </a-row>

        <div class="jl mobile">
          <a-checkbox v-model:checked="checked">记住密码</a-checkbox>
        </div>

        <a-button class="mobile"  block type="primary" @click="submitForm('ruleForm')" :loading="loading">登 录</a-button>
      </a-form>
    </div>
    <footer>
      <div>Uniceramics Expo <a data-v-06aeb1ee="" target="_bland" href="https://beian.miit.gov.cn/">粤ICP备20006720号</a><span data-v-06aeb1ee="">佛山陶联科技发展有限公司</span></div>
    </footer>
  </div>
</template>

<script setup>
  import { ref, reactive, toRaw } from 'vue'
  import {useStore} from 'vuex'
  import {useRouter} from 'vue-router'
  import { Form,message } from 'ant-design-vue';
  import {getToken} from '../../api/index.js';
  const useForm = Form.useForm;

  const router = useRouter();
  const store = useStore();
  const loading = ref(false);

  const checked = ref(!!localStorage.getItem('username'))


  const ruleForm = reactive({
    username:localStorage.getItem('username')||'',
    password:localStorage.getItem('password')||''
  })

  //admin tltest123
  const rules = reactive({
      password: [{ required: true, message: '请输入密码'}],
      username: [{ required: true, message: '请输入用户名'}],
  });


  const { validate, validateInfos } = useForm(ruleForm, rules);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const submitForm = (e)=>{
    let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    console.log(w)
    validate().then(() => {
        loading.value=true;
        getToken(toRaw(ruleForm)).then((res)=>{
            loading.value=false;
            if(res.errcode===200){
              localStorage.setItem('mtoken',res.data.token);
              localStorage.setItem('user',JSON.stringify(res.data.member));
              store.commit("setUser",res.data.member);
              store.commit("setToken",res.data.token);
              // if(w>1000){
              //   router.push({ path: '/index' });
              // }else{
              //   router.push({ path: '/mobile' });
              // }
              
              if(checked.value){
                localStorage.setItem('username',ruleForm.username);
                localStorage.setItem('password',ruleForm.password);
              }else{
                localStorage.setItem('username','');
                localStorage.setItem('password','');
              }

              if(isMobile){
                router.push({ path: '/mobile' });
              }else{
                router.push({ path: '/index' });
              }
          
            }else{
              message.error(res.errmsg);
            }
        })
    }).catch(err => {
      console.log('error', err);
    });
  }

</script>

<style scoped>
.pc{display:block;}
.mobile{display:none;}

.formbox{display: flex; height:calc(100vh - 100px);}
.formbox h2{height:50px; line-height:50px; padding-left:100px; margin-bottom:20px; font-size:24px;}
.formbox>form{width:400px; margin: auto;}

header{display: flex; align-items: flex-end; height:60px;  border-bottom:1px solid #eee; box-shadow: 1px 1px 5px #ccc; position:relative; padding:10px;}
header>h2{ font-size:20px; color:#666; font-weight: bold;}
header>span{font-size:16px; color:#666; font-weight: bold; position:relative; left:8px;}
header img{height: 40px; }
/* footer{ height:60px; background: url(../../assets/footer2.jpg) center no-repeat; background-size:100% 100%;} */
footer{height:40px; box-sizing: content-box; text-align: center; background:#f1f1f1; color:#333;}
footer>div{padding-top:18px;}
footer>div a{color:#00367a;}

@media (max-width:500px){
  .pc{display:none;}
  .mobile{display:block;}
  .formbox>form{width:300px;}
  header img{height: 30px; margin:0px;}
  .formbox .ant-btn-block{margin-top:20px;} 
}
</style>
